<template>
	<div class="container-fluid animatedParent animateOnce my-3">
		<!-- main content -->
		<div class="tab-content">
			<div class="tab-pane animated fadeInUpShort show active go">
				
				<!-- <div class="alert alert-danger alert-dismissible" role="alert">
					<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span>
					</button>
					<strong>通知：</strong> XXX。
				</div> -->
				
				<div class="row my-3">
					<div class="col-md-12">
						<div class="card r-0 shadow">
							<div class="card-header">
								
								<div class="row">
									<div class="col-md-9">
										<!-- 搜索 start -->
										<div class="form-inline mt-3">
											<input v-model="query" class="form-control p-2" placeholder="搜索" type="text">

											<!-- <date-picker class="ml-2" v-model="start_date" format="YYYY-MM-DD" value-type="format"
												input-class="form-control" :editable="false" :clearable="true" placeholder="有效期开始时间">
											</date-picker>
											<div class="ml-2">
												<date-picker v-model="end_date" format="YYYY-MM-DD" value-type="format"
													input-class="form-control" :editable="false" :clearable="true" placeholder="有效期结束时间">
												</date-picker>
											</div> -->

											<span v-if="user_role== 'ADMIN'">
												<select v-model="dept_id" class="form-control  ml-2">
													<option value="0">所有企业</option>
													<option v-bind:key="index" 
														:value="item.id" v-for="(item,index) in departmentlist">
														{{item.department_name}}</option>
												</select>
											</span>

											<select v-model="category" class="form-control ml-2">
												<option value="">所有分类</option>
												<option v-bind:key="index"
													:value="item.value" v-for="(item,index) in categorylist">
													{{item.name}}</option>
											</select>
											
											<select v-model="selectedCardType" class="form-control ml-2">
												<option value="">所有类型</option>
												<option v-bind:key="index"
													:value="item.value" v-for="(item,index) in cardtypelist">
													{{item.name}}</option>
											</select>
											
											<select v-model="send_status" class="form-control ml-2">
												<option value="">所有发送状态</option>
												<option v-bind:key="index" :value="item.value"
													v-for="(item,index) in sendstatuslist">
													{{item.name}}
												</option>
											</select>
											
											<select v-model="status" class="form-control ml-2">
												<option value="">所有核销状态</option>
												<option v-bind:key="index" :value="item.value"
													v-for="(item,index) in statuslist">
													{{item.name}}
												</option>
											</select>

											<button @click="search" type="button" class="btn btn-secondary  ml-2"><i
													class="icon-search"></i>
											</button>
											
											<!-- <button @click="search" type="button" class="btn btn-xs btn-link  ml-2">
												查看过期制度
											</button> -->
											<!-- <div class="form-check ml-2">
												<label class="form-check-label">
													<input 
													v-model="expired" 
													class="form-check-input" type="checkbox"> 包括过期失效制度
												</label>
											</div> -->
										</div>
										<!-- 搜索 end -->
									</div>
									<div class="col-md-3">
										<div class="float-right m-3">
											<template v-if="user_role== 'USER'">
												<button
												@click="exportExcel" type="button" class="btn btn-secondary ml-2">批量导出</button>
												
												<!-- <button
												@click="exportExcel" type="button" class="btn btn-secondary">批量作废</button> -->
												
												<button 
												@click="batSendReady" type="button" class="btn btn-secondary ml-2">批量发放</button>
											</template>	
											<template v-if="user_role== 'ADMIN'">	
												<button
												@click="exportExcel" type="button" class="btn btn-secondary ml-2">导出制卡资料</button>
											</template>
										</div>
									</div>
								</div>
							</div>

							<div class="table-responsive">
								<div v-if="giftcardlist.length > 0">
									<table class="table table-striped table-hover r-0  mb-0">
											<thead>
												<tr class="no-b">
													<th style="width: 60px">
														<!-- <div class="custom-control custom-checkbox">
															<input v-model="check_all" @click.stop="checkAll" type="checkbox"  class="custom-control-input" id="checkedAll"><label class="custom-control-label" for="checkedAll"></label>
														</div> -->
														序号
													</th>
													<th>
														<div class="d-none d-lg-block">福利卡分类</div>
													</th>
													<th>
														<div class="d-none d-lg-block">企业</div>
													</th>
													
													<th>
														<div class="d-none d-lg-block">批次号</div>
													</th>
													
													<th>
														<div class="d-none d-lg-block">服务费类型/费率</div>
													</th>
													<th>
														<div class="d-none d-lg-block">卡号</div>
													</th>
													<!-- <th>
														<div class="d-none d-lg-block">密码</div>
													</th> -->
													<th>
														<div class="d-none d-lg-block">面额(元)</div>
													</th>
													
													<th>
														<div class="d-none d-lg-block">实际金额(元)</div>
													</th>
													
													<th>
														<div class="d-none d-lg-block">卡类型</div>
													</th>
													
													<th>
														<div class="d-none d-lg-block">电子卡链接</div>
													</th>
													
													<th>
														<div class="d-none d-lg-block">有效期</div>
													</th>
													
													<!-- <th>
														<div class="d-none d-lg-block">关联制度</div>
													</th> -->
													
													<!-- <th>
														<div class="d-none d-lg-block">备注</div>
													</th> -->
													
													<th>
														<div class="d-none d-lg-block">发放状态</div>
													</th>
													<th>
														<div class="d-none d-lg-block">核销状态</div>
													</th>
													<th>
														<div class="d-none d-lg-block"></div>
													</th>
													<th></th>
												</tr>
											</thead>
									
											<tbody>
												<tr v-bind:key="index" v-for="(item,index) in giftcardlist"
													:class="{'blue lighten-5':selectedIndex == index}">
													<td>
														{{index+1}}
														<!-- <label class="custom-control custom-checkbox">
															<input v-model="checkedIds" :value="item.id" :id="index" :disabled="item.enabled == -1" class="custom-control-input" type="checkbox"><label class="custom-control-label" :for="index"></label>
														</label> -->
													</td>
													<td>
														<div class="d-none d-lg-block">
														<!-- {{item.category}} -->
														<template v-if="categoryMap.hasOwnProperty(item.category)">
															{{categoryMap[item.category]}}
														</template>
														</div>
													</td>
													<td>
														<div class="d-none d-lg-block">
														<!-- {{item.dept_id}} -->
														<template v-if="deptmap.hasOwnProperty(item.dept_id)">
															{{deptmap[item.dept_id].department_name}}
														</template>
														</div>
													</td>
													
									
													<td>
														<div class="d-none d-lg-block">
															<span class="">
																{{item.batch_no}}
															</span>
														</div>
													</td>
													
													<td>
														<div class="d-none d-lg-block">
															<span class="">
															{{expenseTypeMap[item.expense_type]}}
															/{{item.expense_rate}}%
															</span>
														</div>
													</td>
													
													<td>
														<div class="d-none d-lg-block"><span
																class="font-weight-bold">
																{{item.card_id}}
																</span>
														</div>
													</td>
													
													<!-- <td>
														<div class="d-none d-lg-block"><span
																class="font-weight-bold">
																{{item.card_secret}}
																</span>
														</div>
													</td> -->
													
													<td>
														<div class="d-none d-lg-block"><span
																class="font-weight-bold">
																{{item.price}}
																</span>
														</div>
													</td>
													
													<td>
														<div class="d-none d-lg-block"><span
																class="font-weight-bold">
																{{item.amount}}
																</span>
														</div>
													</td>
													
													<td>
														<div class="d-none d-lg-block">
															{{item.card_type==1?'电子卡':'实体卡'}}
														</div>
													</td>
													
													<td>
														<div class="d-none d-lg-block">
															<span class="font-weight-bold">
																{{item.url}}
															</span>
														</div>
													</td>
													
													<td>
														<div class="d-none d-lg-block">
														{{item.expiry_date=='2099年12月31日'?'长期':item.expiry_date}}
														</div>
													</td>
													
													<!-- <td>
														<div class="d-none d-lg-block">
														{{item.institution_name}}
														<br>
														{{item.institution_id}}
														<i class="icon icon-copy" />
														</div>
													</td> -->
													
													<!-- <td>
														
														<div 
														v-if="item.remark" 
														
														v-tooltip.top="''+item.remark+''"
														
														class="d-none d-lg-block text-ellipsis" style="width: 100px;">
														{{item.remark}} 
														</div>
														
													</td> -->
													
													<td>
														<div class="d-none d-lg-block">
														<!-- <span :class="{'text-success':item.send_status==1,'text-danger':item.send_status==0,'text-dark':item.send_status==-1}">{{item.send_status==1?'已发放':'未发放'}}</span> -->
														
														<template v-if="item.status != -1">
															<template v-if="item.send_status==0">
																<span class="text-danger">制卡中</span>
															</template>
															
															<template v-if="item.send_status==1">
																<span class="text-success">待发放</span>
															</template>
															
															<template v-if="item.send_status==2">
																<span class="text-dark">已发放</span>
															</template>
														</template>
														
														</div>
													</td>
													
													<td>
														<div class="d-none d-lg-block">
														<!-- <span :class="{'text-success':item.status==1,'text-danger':item.status==0,'text-dark':item.status==-1}">{{item.status==1?'已核销':'未核销'}}</span> -->
														
														<template v-if="item.status==0">
															<span class="text-danger">待核销</span>
														</template>
														
														<template v-if="item.status==1">
															<span class="text-success">已核销</span>
														</template>
														
														<template v-if="item.status==-1">
															<span class="text-dark">已作废</span>
														</template>
														
														</div>
													</td>
													
													<td class="form-inline pt-4">
														<button
														 	@click.stop="view(index)" type="button"
														 	class="btn btn-outline-primary btn-xs ml-1"> 预览 </button>
																
														<template v-if="user_role== 'USER'">	 	
															<button
																v-if="item.send_status==1 && item.status==0"
																@click.stop="sendReady(index)" type="button" 
																class="btn btn-outline-primary btn-xs ml-1"> 发放 </button>
																
															 
															<button
																v-if="item.status ==0 "
																@click.stop="removeReady(index)" type="button"
																class="btn btn-outline-primary btn-xs ml-1"> 作废 </button>
														
														</template>
													</td>
													
												</tr>
											</tbody>
											
										</table>
									</div>
									<div v-if="giftcardlist.length == 0">
										<div class="card-body text-lg-center m-5 height-300">
											<h5 class="mb-3">没有福利卡</h5>
											<!-- <button @click="add" type="button" class="btn btn-lg btn-secondary">
												创建制度
											</button> -->
										</div>
									</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row" v-if="giftcardlist.length > 0">
					<div class="col-sm-12 col-md-5">
						<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
							显示 {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
						</div>
					</div>
					<div class="col-sm-12 col-md-7">

						<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>

					</div>
				</div>

			</div>

		</div>
		<!-- //main content -->

		<!-- modal -->
		<!--  福利卡详情 modal -->
		<div class="modal fade" id="iModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">预览福利卡</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
		
						<div class="m-3">
							<!-- <div class="form-group row mt-0 mb-0">
								<label for="staticEmail" class="col-sm-2 col-form-label">批次号</label>
								<div class="col-sm-9 pt-2">
									{{giftcard.batch_no}}
								</div>
							</div> -->
							
							<div class="form-group row mt-0 mb-0">
								<label for="staticEmail" class="col-sm-2 col-form-label">福利卡分类</label>
								<div class="col-sm-9 pt-2">
									<template v-if="categoryMap.hasOwnProperty(giftcard.category)">
										{{categoryMap[giftcard.category]}}
									</template>
									
									<!-- {{giftcard.card_type==1?'电子卡':'实体卡'}} -->
								</div>
							</div>
							
							<div class="form-group row mt-0 mb-0">
								<label for="staticEmail" class="col-sm-2 col-form-label">服务费</label>
								<div class="col-sm-9 pt-2">
									{{expenseTypeMap[giftcard.expense_type]}}
									/{{giftcard.expense_rate}}%
								</div>
							</div>
							
							<div class="form-group row  mt-0 mb-0">
								<label for="staticEmail" class="col-sm-2 col-form-label">面值/金额</label>
								<div class="col-sm-9 pt-2">
									{{giftcard.price}}元/{{giftcard.amount}}元
								</div>
							</div>
							<div class="form-group row  mt-0 mb-0">
								<label for="staticEmail" class="col-sm-2 col-form-label">卡号</label>
								<div class="col-sm-9 pt-2">
									{{giftcard.card_id}}
								</div>
							</div>
							
							<div class="form-group row  mt-0 mb-0" v-if="giftcard.card_type==1">
								<label for="staticEmail" class="col-sm-2 col-form-label">电子卡</label>
								<div class="col-sm-9 pt-2">
									<div id="card_qr"></div>
								</div>
							</div>
							
							<template v-if="giftcard.card_type==0">
							<div class="form-group row  mt-0 mb-0">
								<label for="staticEmail" class="col-sm-2 col-form-label">实体卡</label>
								<div class="col-sm-5 pt-2">
									<template v-if="giftcard.cover == 'DEFAULT'">
										<img src="../assets/img/bg-gc-default.png" class="card-img"/>
									</template>
									<template v-if="giftcard.cover == 'MEAL'" >
										<img src="../assets/img/bg-gc-meal.png" class="card-img"/>
									</template>
									<template v-if="giftcard.cover == 'CAR'">
										<img src="../assets/img/bg-gc-car.png" class="card-img"/>
									</template>
									<template v-if="giftcard.cover == 'COMMON'">
										<img src="../assets/img/bg-gc-common.png" class="card-img"/>
									</template>
								</div>
								
								<div class="col-sm-5 pt-2">
									<!-- <img src="../assets/img/bg-gc-back.png" class="card-img"/> -->
									<div class="card-img card-bg">
										<div id="card_qr" class="card-qr"></div>
										<div class="card-no">{{giftcard.card_id}}</div>
										<div class="card-date" 
										v-if="giftcard.expiry_date != '2099年12月31日'"
										>{{giftcard.expiry_date}}</div>
									</div>
								</div>
							</div>
							<!-- <div class="form-group row  mt-0 mb-0">
								<label for="staticEmail" class="col-sm-3 col-form-label"></label>
								<div class="col-sm-9 pt-2">
									<img src="../assets/img/bg-gc-back.png"/>
								</div>
							</div> -->
							</template>
						</div>
		
					</div>
					<div class="modal-footer">
		
						<button type="button" class="btn btn-secondary" data-dismiss="modal">关闭</button>
		
						<!-- <button @click.stop="sendStock()" :disabled="disabled" type="button" class="btn btn-primary"> 确定下发
						</button> -->
		
					</div>
				</div>
			</div>
		</div>
		<!-- //福利卡详情 modal -->
		
		<!--  作废福利卡 modal -->
		<div class="modal fade" id="rModal" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel2">作废福利卡</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
		
						<div class="m-3">
							<div class="form-group row mt-0 mb-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">批次号</label>
								<div class="col-sm-9 pt-2">
									{{giftcard.batch_no}}
								</div>
							</div>
							
							<div class="form-group row mt-0 mb-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">分类/类型</label>
								<div class="col-sm-9 pt-2">
									<template v-if="categoryMap.hasOwnProperty(giftcard.category)">
										{{categoryMap[giftcard.category]}}/
									</template>
									
									{{giftcard.card_type==1?'电子卡':'实体卡'}}
								</div>
							</div>
							
							<div class="form-group row mt-0 mb-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">服务费</label>
								<div class="col-sm-9 pt-2">
									{{expenseTypeMap[giftcard.expense_type]}}
									/{{giftcard.expense_rate}}%
								</div>
							</div>
							
							<div class="form-group row  mt-0 mb-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">面值/金额</label>
								<div class="col-sm-9 pt-2">
									{{giftcard.price}}元/{{giftcard.amount}}元
								</div>
							</div>
							
							<div class="form-group row  mt-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">卡号</label>
								<div class="col-sm-9 pt-2">
									{{giftcard.card_id}}
								</div>
							</div>
		
		
							<div class="form-group row">
								<label for="staticEmail" class="col-sm-3 col-form-label font-weight-bold">审批验证码</label>
								<div class="col-sm-9">
									<div class="input-group">
										<input v-model="valid_code" type="number" class="form-control"
											placeholder="输入验证码" aria-describedby="button-addon2">
										<div class="input-group-append">
											<button data-key="cancel" @click="sendMessage" :disabled="disabled" v-show="show"
												class="btn btn-primary" type="button" id="button-addon2">发送</button>
		
											<button v-show="!show" class="btn btn-secondary">{{second}}秒后重发</button>
										</div>
									</div>
								</div>
							</div>
		
						</div>
		
					</div>
					<div class="modal-footer">
		
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		
						<button @click.stop="removeConfirm" :disabled="disabled" type="button" class="btn btn-primary"> 确定作废
						</button>
		
					</div>
				</div>
			</div>
		</div>
		<!-- //作废福利卡 modal -->
		
		<!--  发放福利卡 modal -->
		<div class="modal fade" id="pModal" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel2">发放福利卡</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
		
						<div class="m-3">
							<!-- <div class="form-group row mt-0 mb-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">批次号</label>
								<div class="col-sm-9 pt-2">
									{{giftcard.batch_no}}
								</div>
							</div> -->
							
							<div class="form-group row mt-0 mb-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">分类/类型</label>
								<div class="col-sm-9 pt-2">
									<template v-if="categoryMap.hasOwnProperty(giftcard.category)">
										{{categoryMap[giftcard.category]}}/
									</template>
									
									{{giftcard.card_type==1?'电子卡':'实体卡'}}
								</div>
							</div>
							
							<!-- <div class="form-group row mt-0 mb-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">服务费</label>
								<div class="col-sm-9 pt-2">
									{{expenseTypeMap[giftcard.expense_type]}}
									/{{giftcard.expense_rate}}%
								</div>
							</div> -->
							
							<div class="form-group row  mt-0 mb-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">面值/金额</label>
								<div class="col-sm-9 pt-2">
									{{giftcard.price}}元/{{giftcard.amount}}元
								</div>
							</div>
							
							<div class="form-group row  mt-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">卡号</label>
								<div class="col-sm-9 pt-2">
									{{giftcard.card_id}}
								</div>
							</div>
							
							<hr />
							
							<div class="form-group row  mt-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">发放形式</label>
								<div class="col-sm-9">
									<select class="form-control" v-model="card_type" disabled>
										<option v-bind:key="index" :value="item.value"
											v-for="(item,index) in cardtypelist">{{item.name}}</option>
									</select>
								</div>
							</div>
							<div class="form-group row  mt-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">接收人姓名</label>
								<div class="col-sm-9">
									<input v-model="receiver" type="text" class="form-control"
										placeholder="输入接收者姓名">
								</div>
							</div>
							<div class="form-group row  mt-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">接收人手机</label>
								<div class="col-sm-9">
									<input v-model="phone_number" type="number" class="form-control"
										placeholder="输入接收者手机号">
								</div>
							</div>
							
							<div class="form-group row  mt-0">
								<label for="staticEmail" class="col-sm-3 col-form-label">备注(可选)</label>
								<div class="col-sm-9">
									<input v-model="remark" type="text" class="form-control"
										placeholder="输入发放备注">
								</div>
							</div>
		
							<template v-if="card_type == 1">
								<div class="form-group row">
									<label for="staticEmail" class="col-sm-3 col-form-label font-weight-bold">审批验证码</label>
									<div class="col-sm-9">
										<div class="input-group">
											<input v-model="valid_code" type="number" class="form-control"
												placeholder="输入验证码" aria-describedby="button-addon3">
											<div class="input-group-append">
												<button data-key="send" @click="sendMessage" :disabled="disabled" v-show="show"
													class="btn btn-primary" type="button" id="button-addon3">发送</button>
			
												<button v-show="!show" class="btn btn-secondary">{{second}}秒后重发</button>
											</div>
										</div>
									</div>
								</div>
							</template>
		
						</div>
		
					</div>
					<div class="modal-footer">
		
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		
						<button @click.stop="sendConfirm" :disabled="disabled" type="button" class="btn btn-primary"> 确定发放
						</button>
		
					</div>
				</div>
			</div>
		</div>
		<!-- //发放福利卡 modal -->

	</div>
</template>

<script>
	
	const __STOCKTYPE = {
		'NORMAL': '满减券',
		'DISCOUNT': '折扣券',
		'EXCHANGE': '换购券'
	}
	const __STOCKSTATE = {
		'RUNNING': '运行中',
		'UNAUDIT': '审核中',
		'EXPIRED': '已过期',//自定义状态
	}

	import VPagination from '../components/my-page.vue'
	// import DatePicker from 'vue2-datepicker';
	// import 'vue2-datepicker/index.css';
	// import 'vue2-datepicker/locale/zh-cn';
	
	import QRCode from 'qrcodejs2'
	
		
	export default {
		data() {
			return {
				title: ' 制度列表',
				user_role: '',
				
				loader: {},

				token: '',
				outlet_id: 0,
				outlet_name: '',
				user_name: '',
				outlet_level: 0,

				setting: {},
				outletlist: [],

				query: '',

				current_page: 1,
				page_size: 50,
				count: 0,
				total_page: 0,
				show_from: 0,
				show_to: 0,

				total: 0, // 记录总条数
				display: 10, // 每页显示条数
				current: 1, // 当前的页数

				selectedIndex: -1,
				institution: {}, //选中的对象

				//////
				start_date: '',
				end_date: '',
				
				disabled: false,
				
				giftcardlist: [],
				
				enabled: -1,
				enabled_institutions: 0,//投放数
				expired: false,
				
				////
				departmentlist: [],
				dept_id: '',
				deptmap: {}, //key为 dept id, value为 dept
				expenseTypeMap: {
					// 'DEFAULT': '通用',
					// 'MEAL':'餐饮',
					// 'MALL':'商城',
					// 'METRO': '公交地铁',
					// 'PREPAYRECHARGE': '话费充值',
					// 'HOTEL': '酒店',
					// 'TRAIN': '火车票',
					// 'PLANE': '机票',
					// 'CAR': '用车',
					'ENT':'企业付',
					'STAFF':'员工付'
				},
				subexpenseTypeMap: {
					'DEFAULT': '通用',
					'REACH_SHOP':'到店',
					'TAKE_AWAY':'外卖',
					// 'ONLINE_MALL':'线上商城',
					// 'METRO': '公交地铁',
					// 'ONLINE_PREPAY_RECHARGE': '线上充值',
					// 'REACH_SHOP_HOTEL': '到店住宿',
					// 'ONLINE_HOTEL': '在线订酒店',
					// 'ONLINE_TRAIN': '火车票预订',
					// 'ONLINE_PLANE': '机票预订',
					// 'CAR': '用车',
				},
				ppMap: {
					'COMBINATION': '超额部分由个人支付',
					'PERSONAL': '整单全部由个人支付'
				},
				
				isLongTime: false,
				current_date: '',
				
				share_mode: false,
				
				standard_condition_info_list: [],
				
				institution_remark: '',
				
				////可读化使用条件
				ruleKeyMap:{
					"AREA": "可用位置",
					"MEAL_MERCHANT": "可用商户",//到店可用商户
					"QUOTA_TOTAL": "封顶金额",
					"MCC": "商户类型",//到店商户类型
					"SUPPLIER_CHANNEL": "供应商渠道",
					"ELM_MERCHANT": "可用商户",//外卖可用商户
					"TAKE_AWAY_CATEGORY": "商户类型",//外卖商户类型
					"SHIPPING_ADDRESS": "可用订餐地址",
					"BOOK_SWITCH": "预订",
					"MARKED_WORDS": "订餐说明"
					
					
				},
				//福利卡分类
				categorylist: this.GLOBAL.categorylist,
				// categorylist: this.GLOBAL.categorylist,
				selected_category: '',
				
				// categoryMap: {
				// 	"DEFAULT": "弹性福利",
				// 	"MEAL": "餐食外卖",
				// 	"CAR": "交通出行",
				// 	"COMMON": "工会福利",
				// },
				categoryMap: [],
				category: '',
				
				giftcard: {},
				
				statuslist: [{
						name: '待核销',
						value: 0
					},
					{
						name: '已核销',
						value: 1
					},
					{
						name: '已作废',
						value: -1
					},
				],
				status: '',
				
				////
				valid_code: '',
				show: true,
				second: '',
				timer: null,
				
				////
				receiver: '',
				phone_number: '',
				card_type: '',
				cardtypelist: [
					// {name: '选择类型', value: ''},
					{name: '实体卡', value: '0'},
					{name: '电子卡', value: '1'},
				],
				remark: '',
				
				////
				sendstatuslist: [{
						name: '制卡中',
						value: 0
					},
					{
						name: '待发放',
						value: 1
					},
					{
						name: '已发放',
						value: 2
					},
				],
				send_status: '',
				selectedCardType: '',
			}

		},
		components: {
			VPagination,
			// DatePicker,
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.dept_id = lsLoginData.dept_id;
			self.user_role = lsLoginData.user_role;
			
			//构造categoryMap
			self.categoryMap = [];
			for(let i=0; i<this.GLOBAL.categorylist.length; i++){
				let et = this.GLOBAL.categorylist[i];
				self.categoryMap[et.value] = et.name;
			}
		},

		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//先取预载数据，再取业务数据
			if(self.user_role == 'ADMIN'){
				self.init(() => {
					self.initData();
				})
			}
			else{
				self.initData();
			}
		},
		methods: {

			init(callback) {
				let self = this;
			
				//
				this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							// query: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.departmentlist = data.data.departments;
							
							for (let i = 0; i < self.departmentlist.length; i++) {
								let dept = self.departmentlist[i];
								self.deptmap[dept.id] = dept;
							}
							console.log('--- dept map ->', self.deptmap);
							
							self.current_date = data.data.current_date;
							
							if (typeof callback == 'function') {
								callback()
							}
						} 
						else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} 
						else {
							alert(data.message)
						}
					});
				//END
			
			},

			initData() {
				let self = this;
				
				self.loader = self.$loading.show();

				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getGiftCardList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							category: self.category,
							card_type: self.selectedCardType,
							start_date: self.start_date,//有效期，预留
							end_date: self.end_date,
							status: self.status,
							send_status: self.send_status,
							query: self.query,
							current_page: self.current_page,
							page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						
						self.loader.hide();
						
						if (data.code == 200) {

							self.giftcardlist = data.data.list;
							
							self.start_date = data.data.start_date;
							self.end_date = data.data.end_date;

							self.current_page = data.data.page_num;
							self.page_size = data.data.page_size;
							self.count = data.data.total;
							self.total_page = Math.ceil(self.count / self.page_size);
							console.log('--- self.total_page ->', self.total_page)

							self.show_from = self.page_size * (self.current_page - 1) + 1;
							self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
								.current_page : self.count;

							self.total = self.count; // 记录总条数
							self.display = self.page_size; // 每页显示条数
							self.current = self.current_page; // 当前的页数

							// self.giftcardlist = []; //重置
							// for (let institution of data.data.list) {
							// 	// institution.typeDesc = __STOCKTYPE[institution.stock_type];
							// 	// institution.stateDesc = institution.stock_state?__STOCKSTATE[institution.stock_state]:""
								
							// 	self.giftcardlist.push(institution);
							// }
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END

			},

			pagechange: function(currentPage) {
				console.log(currentPage);
				// ajax请求, 向后台发送 currentPage, 来获取对应的数据
				this.current_page = currentPage;
				this.initData();
			},

			search() {
				let self = this;

				self.initData();
			},
			
			// add(){
			// 	let self = this;
				
			// 	self.$router.push({
			// 	    name: 'addgiftcard',
			// 	    params: {
			// 	        outlet_id: self.outlet_id
			// 	    }
			// 	}) 
			// },
			
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.init(() => {
					self.initData();
					self.employee_id = 0;
				})

			},
			
			view(index) {
				console.log(index);
				let self = this;
							
				let giftcard = self.giftcardlist[index];
				console.log(index, giftcard);
							
				self.selectedIndex = index;
				self.giftcard = Object.assign({},giftcard);
				
				// if(self.giftcard.card_type==1){//电子卡生成二维码
					setTimeout(()=>{
						self.initQrcode();
					},1000)
				// }
				
				
				$('#iModal').modal('show');
			
			},
			
			
			sendReady(index) {
				console.log(index);
				let self = this;
							
				let giftcard = self.giftcardlist[index];
				console.log(index, giftcard);
							
				self.selectedIndex = index;
				self.giftcard = Object.assign({},giftcard);
				
				self.valid_code = '';
				self.disabled = false;			
				
				self.card_type = self.giftcard.card_type;
				self.receiver = '';
				self.phone_number = '';
				self.remark = '';
							
				$('#pModal').modal('show');
			
			},
			
			sendConfirm(){
				let self = this;
			
				console.log('--- self.giftcard ->', self.giftcard)
				
				if (!self.receiver) {
					self.$toasted.error('请输入接收者姓名', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.phone_number) {
					self.$toasted.error('请输入接收者手机号', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				//仅发放电子卡时需要审批
				if (self.card_type == 1 && !self.valid_code) {
					self.$toasted.error('请输入审批验证码', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				self.disabled = true;
			
				// 组装数据
				let postData = {
					token: self.token,
					dept_id: self.giftcard.dept_id,
					id: self.giftcard.id,
					valid_code: self.valid_code,
					name: self.receiver,
					phone_number: self.phone_number,
					remark: self.remark,
					card_type: self.card_type,
					nonce_str: self.giftcard.nonce_str || '',
				}
				
				let postData2 = new FormData();
				for(var key in postData){
					console.log(key+':'+postData[key]);
					postData2.append(key,postData[key]);
				}
			
				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'sendGiftCard', 
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;
			
						if (data.code == 200) {
							
							if (data.data.result_code == 'success') {
								// let _giftcard= data.data.giftcard;
								// self.$set(self.giftcardlist, self.selectedIndex, _giftcard);
								// self.giftcardlist.splice(self.selectedIndex, 1)
								
								self.$toasted.success( '发放成功', {
									position: 'top-center',
									duration: '2000',
								})
								
								self.initData();
								
								$('#pModal').modal('hide');
							}
							else {
								self.$toasted.error( data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
							
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else {
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},
			
			
			removeReady(index) {
				console.log(index);
				let self = this;
							
				let giftcard = self.giftcardlist[index];
				console.log(index, giftcard);
							
				self.selectedIndex = index;
				self.giftcard = Object.assign({},giftcard);
				
				self.valid_code = '';
							
							
				$('#rModal').modal('show');
			
			},
			
			//删除制度
			removeConfirm() {
				let self = this;
			
				console.log('--- self.giftcard ->', self.giftcard)
				
				if (!self.valid_code) {
					self.$toasted.error('请输入审批验证码', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				self.disabled = true;
			
				// 组装数据
				let postData = {
					token: self.token,
					dept_id: self.giftcard.dept_id,
					id: self.giftcard.id,
					valid_code: self.valid_code,
					nonce_str: self.giftcard.nonce_str || '',
				}
				
				let postData2 = new FormData();
				for(var key in postData){
					console.log(key+':'+postData[key]);
					postData2.append(key,postData[key]);
				}
			
				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'cancelGiftCard', 
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;
			
						if (data.code == 200) {
							
							if (data.data.result_code == 'success') {
								// let _giftcard= data.data.giftcard;
								// self.$set(self.giftcardlist, self.selectedIndex, _giftcard);
								// self.giftcardlist.splice(self.selectedIndex, 1)
								
								self.$toasted.success( '作废成功', {
									position: 'top-center',
									duration: '2000',
								})
								
								self.initData();
								
								$('#rModal').modal('hide');
							}
							else {
								self.$toasted.error( data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
							
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else {
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},
			
			exportExcel(){
			    let self = this;
				
				let formData = new FormData();
				
				formData.append('token', self.token);
				formData.append('dept_id', self.dept_id);
				formData.append('category', self.category);
				formData.append('status', self.status);
				formData.append('send_status', self.send_status);
				formData.append('query', self.query);
				
				self.loader = self.$loading.show();
				
				// 调用接口
				self.axios.post(self.GLOBAL.baseURI + 'exportGiftCards',
					formData
					,{
					   responseType: 'blob'
					}
				)
				.then(function(response) {
					console.log('---response->\n',response);
					
					const url = URL.createObjectURL(new Blob([response.data], {
						// type: 'application/vnd.ms-excel',
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					}))
					const link = document.createElement('a')
					link.href = url
					let fileName = "制卡资料_"+new Date().getTime();
					link.setAttribute('download', fileName)
					document.body.appendChild(link)
					link.click()
					
					self.loader.hide();
				})
				.catch(function(error) {
					console.error(error);
					self.loader.hide();
				});
				//END
			
			},
			
			batSendReady(){
				//方式2：用独立页
				//必须 用 name，否则无法传参
				this.$router.push({ name: 'importgiftcards',})
			
			},
			
			////
			sendMessage(e) {
				let self = this;
			
				// self.$toasted.success('发送成功', {
				// 	position: 'top-center',
				// }).goAway(2000)
			
				// //倒计时
				// self.countdown();
				
				let act = e.currentTarget.dataset.key;//动作
			
				if (self.disabled) return;
			
				self.disabled = true;
			
				// 组装数据
				let postData = {
					token: self.token,
					act: act,//动作：send下发，cancel作废
				}
			
				console.log('--- postData ->', postData)
			
				this.axios.get(this.GLOBAL.baseURI + 'sendGiftCardMessage', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
			
						self.disabled = false;
			
						if (data.code == 200) {
			
							self.$toasted.success('发送成功', {
								position: 'top-center',
							}).goAway(2000)
			
							//倒计时
							self.countdown();
						} else if (data.code == 403) {
							self.$router.push({
								name: '403'
							});
							return;
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
			},
			
			countdown() {
				const TIME_COUNT = 60;
				if (!this.timer) {
					this.second = TIME_COUNT;
					this.show = false;
					this.timer = setInterval(() => {
						if (this.second > 0 && this.second <= TIME_COUNT) {
							this.second--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
			},
			
			initQrcode(){
				let self = this;
				console.log('---- self.giftcard ->',self.giftcard)
				
				$('#card_qr').empty();
				
				let url = self.giftcard.card_type==1?self.giftcard.url:'http://fulipay.net/'+self.giftcard.dept_id;
				console.log('---- url->',url)
				
				new QRCode('card_qr', {
					text: url, // 需要转换为二维码的内容
					width: self.giftcard.card_type==1?256:80,
					height: self.giftcard.card_type==1?256:80,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			}
			
		},
	}
</script>

<style>
	.mx-icon-calendar,
	.mx-icon-clear {
		right: 28px !important;
	}
	.text-ellipsis{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.modal-lg {
	    max-width: 1024px !important;
	}
	
	.card-img{
		width: 360px;
		height: 227px;
	}
	
	.card-bg{
		width: 360px;
		height: 227px;
		background-image: url('../assets/img/bg-gc-back.png'); 
		background-repeat:no-repeat;
		background-size:100% 100%;
	}
	
	.card-qr{
		position: absolute;
		top:30px;
		left: 270px;
	}
	.card-no{
		position: absolute;
		top:140px;
		left: 80px;
		font-size: 12px;
	}
	.card-date{
		position: absolute;
		top:140px;
		left: 250px;
		font-size: 12px;
		font-style:italic;
	}
</style>
